/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-form-validator-core';
import { connect } from 'react-redux';
import { isEmpty, isObject, clone } from 'lodash/core';
import { unionWith, isEqual } from 'lodash';
import { fetchContactPersons, fetchServices } from '../../actions/user';
import { getXTimefromSpecificDate } from '../../../Share/utils/dateUtil';
import {
  onUpdateNewAssignmentsTemp,
  UpdateContactPersons,
} from '../../actions';
import {
  createDefaultAssignment,
  createDefaultContactPerson,
  generateUniqueId,
  isAddressEdited,
  isContactPersonValid,
  isIE,
  selectDefaultService,
} from '../../../Share/utils';
import {
  SINGLE_FORM,
  MULTI_FORM,
  ASSIGNMENT_TYPES,
} from '../../../Share/constants';
import moment from 'moment';
import QuickOrderForm from './QuickOrderForm';
import history from '../../../Share/utils/history';
import ContactPersonModal from '../NewAssignment/ContactPersonModal';
import './style.scss';
import { PreviewAssignment } from '../NewAssignment';
import SuccessMessage from '../NewAssignment/SuccessMessage';
import { subscribe, unSubscribe } from '../../actions/eventBus';
import AdvancedAssigmentWizard from '../NewAssignment/AdvancedAssigmentWizard';
import {
  Modal,
  Translate,
  LeftDrawerSlide,
  Dialog,
  RouteLeavingModal,
} from '../../../Share/components';
import { fallbacks } from 'i18n-js';
import { ContactPersonEdit } from '..';
import { createRef } from 'react';

const propTypes = {
  assignment: PropTypes.arrayOf(PropTypes.shape({})),
  skills: PropTypes.arrayOf(PropTypes.shape({})),
  alternativeLanguages: PropTypes.shape({}),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  profile: PropTypes.shape({}),
  languages: PropTypes.arrayOf(PropTypes.shape({})),
  contactPersons: PropTypes.arrayOf(PropTypes.shape({})),
  executeGetContactPersons: PropTypes.func,
  executeGetServices: PropTypes.func,
  onSwitchToDetailOrder: PropTypes.func,
  date: PropTypes.string,
  onClose: PropTypes.func,
  showQuickOrder: PropTypes.bool,
  RouteLeavingGuardHandler: PropTypes.func,
};

const defaultProps = {
  assignment: {},
  skills: [],
  services: [],
  contactPersons: [],
  profile: {},
  languages: [],
  alternativeLanguages: {},
  executeGetContactPersons: () => {},
  executeGetServices: () => {},
  onSwitchToDetailOrder: () => {},
  onClose: () => {},
  date: null,
  showQuickOrder: false,
  RouteLeavingGuardHandler: () => {},
};

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

ValidatorForm.addValidationRule('urlformat', value => {
  if (value && value == '') return true;
  if (value && (value.startsWith('http://') || value.startsWith('https://'))) {
    return true;
  }
  return false;
});

class QuickOrder extends Component {
  constructor(props) {
    super(props);
    const {
      assignmentTemp,
      alternativeLanguages,
      skills,
      services,
      contactPersons,
      profile,
      onResetDate,
      date,
    } = props;
    let contactPersonsList = clone(contactPersons);
    if (props.newContactPersons.length) {
      contactPersonsList = unionWith(
        contactPersonsList,
        props.newContactPersons,
        isEqual,
      );
    }
    if (isEmpty(assignmentTemp)) {
      this.state = {
        assignment: {
          ...createDefaultAssignment(
            SINGLE_FORM,
            skills,
            services,
            contactPersonsList,
            profile,
            date,
          ),
        },
        contactPerson: createDefaultContactPerson(),
        showContactPersonModal: false,
        showPreview: false,
        showSuccessMessage: false,
        showAdvancedOrderWizard: false,
        successMessageDetails: [],
        visibleRouteLeavingModal: false,
        isAddDemands: false,
        isAltLanguageAdded: false,
        alternativeLanguages: [],
        isAltServicesAdded: false,
        alternativeServiceTypes: [],
        alternativeServices: {},
      };
    } else {
      this.state = {
        assignment: {
          ...assignmentTemp,
          languages: skills,
        },
        contactPerson: createDefaultContactPerson(),
        showContactPersonModal: false,
        showPreview: false,
        showSuccessMessage: false,
        showAdvancedOrderWizard: false,
        successMessageDetails: [],
        visibleRouteLeavingModal: false,
        isAddDemands: false,
        alternativeLanguages:
          alternativeLanguages[assignmentTemp.language.value] || [],
        isAltLanguageAdded: !!assignmentTemp.alternativeLanguage.value,
        isAltServicesAdded: !!assignmentTemp.alternativeTypeOfAssignment.value,
        alternativeServiceTypes: [],
        alternativeServices: {},
      };
    }
    onResetDate();
  }

  componentDidMount() {
    const { assignment } = this.state;
    const { executeGetContactPersons, executeGetServices, languages } =
      this.props;
    executeGetContactPersons();

    const { startTime, endTime } = assignment.dateRange;
    const { language } = assignment;

    const callbackUpdateServices = (allowSelectServices, _assignment) => {
      let selectedService =
        this.selectDefaultAssignmentType(allowSelectServices);
      if (
        _assignment.typeOfAssignment &&
        _assignment.typeOfAssignment.value &&
        _assignment.typeOfAssignment.value != ''
      ) {
        const matchingServices = allowSelectServices.filter(
          x => x.ServiceIdentifier == _assignment.typeOfAssignment.value,
        );
        selectedService =
          matchingServices.length > 0 ? matchingServices[0] : selectedService;
      }

      const typeOfAssignment =
        _assignment.typeOfAssignment !== '' &&
        _assignment.typeOfAssignment &&
        !isEmpty(_assignment.typeOfAssignment)
          ? _assignment.typeOfAssignment
          : selectDefaultService(allowSelectServices);

      const selectedAssignmentTypeData = [selectedService];

      let genderRequirement = _assignment.genderRequirement;
      let genderValue = _assignment.genderValue;
      let certificationLevels = _assignment.certificationLevels;
      let isAllowLowerLevel = _assignment.isAllowLowerLevel;
      let isAllowOnlyLevel = _assignment.isAllowOnlyLevel;
      let defaultDoAllowAlternativeService =
        _assignment.defaultDoAllowAlternativeService;

      if (selectedService) {
        if (genderRequirement === null) {
          const defaultDoRequireCorrectGender =
            selectedService.DefaultDoRequireCorrectGender;
          genderRequirement =
            defaultDoRequireCorrectGender === null
              ? false
              : defaultDoRequireCorrectGender;
        }
        if (genderValue === null) {
          const defaultGenderIdPreferred =
            selectedService.DefaultGenderIdPreferred;
          genderValue =
            defaultGenderIdPreferred !== null
              ? {
                  name: '',
                  value: defaultGenderIdPreferred.toString(),
                }
              : defaultGenderIdPreferred;
        }
        if (certificationLevels === undefined) {
          const defaultCompetenceIdentifier =
            selectedService.DefaultCompetenceIdentifier;
          certificationLevels =
            defaultCompetenceIdentifier === null
              ? undefined
              : { name: '', value: defaultCompetenceIdentifier };
        }
        if (isAllowLowerLevel === null) {
          const defaultDoRequireCompetenceLevelOrHigher =
            selectedService.DefaultDoRequireCompetenceLevelOrHigher;
          isAllowLowerLevel =
            defaultDoRequireCompetenceLevelOrHigher === null
              ? false
              : defaultDoRequireCompetenceLevelOrHigher;
        }
        if (isAllowOnlyLevel === null) {
          const defaultDoRequireExactCompetenceLevel =
            selectedService.DefaultDoRequireExactCompetenceLevel;
          isAllowOnlyLevel =
            defaultDoRequireExactCompetenceLevel === null
              ? false
              : defaultDoRequireExactCompetenceLevel;
        }
        if (!_assignment.isEdit) {
          defaultDoAllowAlternativeService =
            selectedService.DefaultDoAllowAlternativeService;
        }
      }

      const alternativeServices =
        this.checkAlternativeServices(allowSelectServices);
      const alternativeServiceTypes =
        alternativeServices[typeOfAssignment.value] || [];

      let alternativeTypeOfAssignment = { name: null, value: null };
      let alternativeTypeOfAssignmentDisplay = { name: null, value: null };

      if (_assignment.isEdit === true) {
        if (
          _assignment.alternativeTypeOfAssignment.value &&
          _assignment.alternativeTypeOfAssignment.value !== null
        ) {
          const selectedAltService = allowSelectServices.find(
            service =>
              service.ServiceIdentifier ===
              _assignment.alternativeTypeOfAssignment.value,
          );
          alternativeTypeOfAssignmentDisplay = {
            name: selectedAltService.ServiceName,
            value: selectedAltService.ServiceIdentifier,
          };
          if (
            _assignment.typeOfAssignment.value ===
            _assignment.serviceIdentifierOrdered
          ) {
            alternativeTypeOfAssignment = {
              name: selectedAltService.ServiceName,
              value: selectedAltService.ServiceIdentifier,
            };
          }
        }
      } else {
        if (selectedService.DefaultDoAllowAlternativeService === true) {
          const selectedAltService = allowSelectServices.find(
            service =>
              service.ServiceIdentifier ===
              selectedService.ServiceAlternatives[0].ServiceIdentifier,
          );
          alternativeTypeOfAssignment = {
            name: selectedAltService.ServiceName,
            value: selectedAltService.ServiceIdentifier,
          };
        }
      }

      this.setState({
        assignment: {
          ...this.state.assignment,
          services: allowSelectServices,
          typeOfAssignment,
          selectedAssignmentTypeData,
          isBankIDRequired: false,
          genderRequirement,
          genderValue,
          certificationLevels,
          isAllowLowerLevel,
          isAllowOnlyLevel,
          alternativeTypeOfAssignment: alternativeTypeOfAssignment,
          alternativeTypeOfAssignmentDisplay,
          defaultDoAllowAlternativeService,
          typeOfAssignmentDisplay: typeOfAssignment,
        },
        alternativeServices,
        alternativeServiceTypes,
      });
    };

    const params = {
      language: assignment.language
        ? assignment.language.value == '-' || assignment.language.value == ''
          ? languages[1].value
          : assignment.language.value
          ? assignment.language.value
          : languages[1].value
        : languages[1].value,
      startTime,
      endTime,
      assignment,
    };

    executeGetServices(params, callbackUpdateServices);

    ValidatorForm.addValidationRule('checkNumLength', (value, number) => {
      if (value.toString().length !== Number(number)) {
        return false;
      }
      return true;
    });
    subscribe('SuccessFullOrderCreation', ({ isBasic, assignmentDetails }) => {
      if (isBasic) {
        this.setState({
          showPreview: false,
          showSuccessMessage: true,
          successMessageDetails: assignmentDetails,
        });
      }
    });
    this.creteContactEvent = subscribe(
      'SuccessfullyContactPersonCreate',
      ({ ContactPersonIdentifier, type }) => {
        const addContatctPerson = {};
        if (type == 'ContactPersonOrderer') {
          addContatctPerson.orderContactPerson = ContactPersonIdentifier;
        } else {
          addContatctPerson.contactPerson = ContactPersonIdentifier;
        }
        this.setState({
          assignment: {
            ...this.state.assignment,
            ...addContatctPerson,
          },
        });
      },
    );
  }
  componentWillUnmount() {
    unSubscribe('SuccessfullyContactPersonCreate', this.creteContactEvent);
  }

  componentDidUpdate(prevProps, prevState) {
    const { defaultDoAllowAlternativeService, typeOfAssignment, services } =
      this.state.assignment;
    if (
      prevState.assignment.defaultDoAllowAlternativeService !==
      defaultDoAllowAlternativeService
    ) {
      if (defaultDoAllowAlternativeService) {
        const selectedService = services.find(
          service => service.ServiceIdentifier === typeOfAssignment.value,
        );
        if (selectedService.ServiceAlternatives?.length > 0) {
          const selectedAltService = services.find(
            service =>
              service.ServiceIdentifier ===
              selectedService.ServiceAlternatives[0].ServiceIdentifier,
          );
          this.setState({
            assignment: {
              ...this.state.assignment,
              alternativeTypeOfAssignment: {
                name: selectedAltService.ServiceName,
                value: selectedAltService.ServiceIdentifier,
              },
            },
          });
        } else {
          this.setState({
            assignment: {
              ...this.state.assignment,
              alternativeTypeOfAssignment: { name: null, value: null },
            },
          });
        }
      } else {
        this.setState({
          assignment: {
            ...this.state.assignment,
            alternativeTypeOfAssignment: { name: null, value: null },
          },
        });
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.date &&
      prevState.assignment.dateRange &&
      prevState.assignment.dateRange.ranges.startDate !== nextProps.date
    ) {
      const tempAssignment = {
        ...prevState.assignment,
        dateRange: {
          ranges: {
            endDate: nextProps.date,
            startDate: nextProps.date,
            key: prevState.assignment.dateRange.ranges.key,
          },
          startTime: prevState.assignment.dateRange.startTime,
          endTime: prevState.assignment.dateRange.endTime,
          durationDifference: prevState.assignment.dateRange.durationDifference,
        },
      };
      nextProps.onResetDate();
      return {
        assignment: tempAssignment,
      };
    }

    if (
      !isEqual(prevState.assignment.contactPersons, nextProps.contactPersons)
    ) {
      return {
        assignment: {
          ...prevState.assignment,
          contactPersons: nextProps.contactPersons,
        },
      };
    }

    if (prevState.assignment.services.length !== nextProps.services.length) {
      return {
        assignment: {
          ...prevState.assignment,
        },
      };
    }

    if (isEmpty(prevState.assignment.profile)) {
      if (
        nextProps.profile.customerIdentifier !== '' &&
        nextProps.profile.customerNumber !== '' &&
        !isEmpty(nextProps.profile.customerAddress) &&
        !prevState.assignment.isEdit
      ) {
        return {
          assignment: {
            ...prevState.assignment,
            profile: nextProps.profile || {},
            addressLine: nextProps.profile.customerAddress.AddressRow1 || '',
            city: nextProps.profile.customerAddress.City || '',
            district: nextProps.profile.customerAddress.CareOf || '',
            postalCode: nextProps.profile.customerAddress.PostalCode.toString(),
            orderCompanyName: nextProps.profile.customerName,
            orderLocation: `${
              !nextProps.profile.customerAddress.CareOf
                ? ''
                : `${nextProps.profile.customerAddress.CareOf}, `
            }${nextProps.profile.customerAddress.AddressRow1}, ${
              nextProps.profile.customerAddress.PostalCode
            }, ${nextProps.profile.customerAddress.City}`,
          },
        };
      }
    }
    return null;
  }

  ConvergeValidate = () => {
    const { assignment } = this.state;
    let isValid = true;
    const selectedDate =
      assignment.dateRange.ranges.startDate.format('DD/MM/YYYY');
    const todayDate = moment().format('DD/MM/YYYY');
    if (selectedDate === todayDate && !assignment.isEdit) isValid = false;
    return isValid;
  };

  handleSubmit = () => {
    const { assignment, showPreview, isAddDemands } = this.state;
    const { profile } = this.props;
    let isValid = true;
    let validContactPersonUponInterpretation = true;
    let validContactPersonOrderer = true;

    if (this.ConvergeValidate()) {
      if (
        !isContactPersonValid(
          profile.ContactPersonUponInterpretationRequiresEmail,
          assignment.contactPerson,
          assignment.contactPersons,
        )
      ) {
        validContactPersonUponInterpretation = false;
      }
      if (
        !isContactPersonValid(
          profile.ContactPersonOrdererRequiresEmail,
          assignment.orderContactPerson,
          assignment.contactPersons,
        )
      ) {
        validContactPersonOrderer = false;
      }
      isValid =
        validContactPersonUponInterpretation && validContactPersonOrderer;

      this.props.onSwitchToDetailOrder({
        ...assignment,
        isAddressEdited: isAddressEdited(assignment, profile),
      });
      if (isValid) {
        if (!isAddDemands) {
          this.setState({
            showPreview: true,
          });
        } else {
          this.setState({ showAdvancedOrderWizard: true, isAddDemands: false });
        }
      }
    }
  };
  quickOrderRef = createRef();
  handleSwitchToMultipleOrder = () => {
    const { assignment } = this.state;

    const { skills, services, contactPersons, profile } = this.props;

    const multiFormObj = createDefaultAssignment(
      MULTI_FORM,
      skills,
      services,
      contactPersons,
      profile,
    );
    multiFormObj.form = {
      ...multiFormObj.form,
      serialDateRanges: assignment.serialDateRanges,
      dateRange: assignment.dateRange,
      contactPerson: assignment.contactPerson,
      contactNumber: assignment.contactNumber,
      contactEmail: assignment.contactEmail,
      contactDirectNumber: assignment.contactDirectNumber,
      orderContactPerson: assignment.orderContactPerson,
      orderContactNumber: assignment.orderContactNumber,
      orderContactDirectNumber: assignment.orderContactDirectNumber,
      orderEmail: assignment.orderEmail,
      typeOfAssignment: assignment.typeOfAssignment,
      language: assignment.language,
      alternativeLanguage: assignment.alternativeLanguage,
    };

    if (this.ConvergeValidate()) {
      this.props.onSwitchToDetailOrder([multiFormObj]);
      history.push('/advanced-order-form');
    }
  };

  changeTimeOnType = assignmentValue => {
    const { assignment } = this.state;
    const { dateRange } = assignment;
    const clonedDateRange = clone(dateRange);
    if (assignmentValue === ASSIGNMENT_TYPES.TELEPHONE) {
      clonedDateRange.endTime = getXTimefromSpecificDate(
        dateRange.startTime,
        30,
        'minutes',
      );
    } else {
      clonedDateRange.endTime = getXTimefromSpecificDate(
        dateRange.startTime,
        60,
        'minutes',
      );
    }
    return clonedDateRange.endTime;
  };

  resetMeetingRelatedData() {
    return {
      meetingLink: null,
      meetingPassCode: null,
      meetingPlatform: { name: null, value: null },
      phoneNumber: null,
      isBankIDRequired: null,
    };
  }
  selectDefaultAssignmentType = services => {
    const filteredTeleService = services.filter(x =>
      x.ServiceName.includes('Tele'),
    );
    if (filteredTeleService.length === 0) return services[0];
    return filteredTeleService[0];
  };

  addAltLanguage = action => {
    this.setState({
      isAltLanguageAdded: action,
      assignment: {
        ...this.state.assignment,
        alternativeLanguage: action
          ? this.state.assignment.alternativeLanguage
          : { name: null, value: null },
      },
    });
  };

  addAltServiceType = action => {
    this.setState({
      isAltServicesAdded: action,
      assignment: {
        ...this.state.assignment,
        alternativeTypeOfAssignment: action
          ? this.state.assignment.alternativeTypeOfAssignment
          : { name: null, value: null },
      },
    });
  };

  handleChange = obj => {
    const { alternativeLanguages } = this.props;
    const { assignment, alternativeServices, alternativeServiceTypes } =
      this.state;
    const { services, isEdit } = assignment;

    if (obj.language) {
      this.setState({
        alternativeLanguages: alternativeLanguages[obj.language.value] || [],
        isAltLanguageAdded: false,
      });
    }

    if (obj.typeOfAssignment) {
      this.setState({
        alternativeServiceTypes:
          alternativeServices[obj.typeOfAssignment.value] || [],
        isAltServicesAdded: false,
      });
      // This is removed due to client's requirement
      // if (isEdit && isEdit === true) {
      //   this.setState({
      //     alternativeServiceTypes,
      //   });
      // } else {
      //   this.setState({
      //     alternativeServiceTypes:
      //       alternativeServices[obj.typeOfAssignment.value] || [],
      //     isAltServicesAdded: false,
      //   });
      // }
    }

    const filteredItems = services.filter(x => {
      if (obj.typeOfAssignment === undefined) {
        return x.ServiceIdentifier === assignment.typeOfAssignment.value;
      }
      return x.ServiceIdentifier === obj.typeOfAssignment.value;
    });
    const { executeGetServices, languages } = this.props;

    let isAllowLowerLevel = assignment.isAllowLowerLevel;
    let isAllowOnlyLevel = assignment.isAllowOnlyLevel;
    let genderRequirement = assignment.genderRequirement;
    let defaultDoAllowAlternativeService =
      assignment.defaultDoAllowAlternativeService;
    if (obj.genderRequirement === true || obj.genderRequirement === false) {
      genderRequirement = obj.genderRequirement;
    }
    if (obj.isAllowLowerLevel === true || obj.isAllowLowerLevel === false) {
      isAllowLowerLevel = obj.isAllowLowerLevel;
    }
    if (obj.isAllowOnlyLevel === true || obj.isAllowOnlyLevel === false) {
      isAllowOnlyLevel = obj.isAllowOnlyLevel;
    }
    let genderValue = obj.genderValue
      ? obj.genderValue
      : assignment.genderValue;
    let certificationLevels = obj.certificationLevels
      ? obj.certificationLevels
      : assignment.certificationLevels;

    if (obj.typeOfAssignment) {
      if (filteredItems.length > 0) {
        isAllowLowerLevel =
          filteredItems[0].DefaultDoRequireCompetenceLevelOrHigher;
        isAllowOnlyLevel =
          filteredItems[0].DefaultDoRequireExactCompetenceLevel;
        genderRequirement =
          filteredItems[0].DefaultDoRequireCorrectGender === null
            ? false
            : filteredItems[0].DefaultDoRequireCorrectGender;
        genderValue =
          filteredItems[0].DefaultGenderIdPreferred !== null
            ? {
                name: '',
                value: filteredItems[0].DefaultGenderIdPreferred.toString(),
              }
            : null;
        certificationLevels =
          filteredItems[0].DefaultCompetenceIdentifier === null
            ? undefined
            : { name: '', value: filteredItems[0].DefaultCompetenceIdentifier };
        defaultDoAllowAlternativeService = assignment.isEdit
          ? defaultDoAllowAlternativeService
          : filteredItems[0].DefaultDoAllowAlternativeService;
      }
    }

    const isChangeLanguage =
      obj.language !== null &&
      obj.language !== undefined &&
      obj.language !== '';
    if (isChangeLanguage || obj.isChangeSerialDuration) {
      let { startDate, endDate } = assignment.dateRange.ranges;
      const { startTime, endTime } = assignment.dateRange;
      if (obj.isChangeSerialDuration) {
        startDate = obj.dateRange.ranges.startDate;
        endDate = obj.dateRange.ranges.endDate;
      }
      startDate.set({
        hour: startTime.hours(),
        minute: startTime.minutes(),
      });
      endDate.set({ hour: endTime.hours(), minute: endTime.minutes() });
      // Checking the time - is from next day or current day
      const beginningTime = moment({
        h: startTime.hours(),
        s: startTime.minutes(),
      });
      const endingTime = moment({
        h: endTime.hours(),
        s: endTime.minutes(),
      });

      if (endingTime.isBefore(beginningTime)) {
        var endDateHour = endDate.hours();
        var endDateMinute = endDate.minutes();
        endDate = moment(startDate);
        endDate.hours(endDateHour).minutes(endDateMinute);
        endDate.add(1, 'day');
      } else if (endingTime.isSame(beginningTime)) {
        var endDateHour = endDate.hours();
        var endDateMinute = endDate.minutes();
        endDate = moment(startDate);
        endDate.hours(endDateHour).minutes(endDateMinute);
        endDate.add(1, 'day');
      } else {
        var endDateHour = endDate.hours();
        var endDateMinute = endDate.minutes();
        endDate = moment(startDate);
        endDate.hours(endDateHour).minutes(endDateMinute);
      }
      this.setState({
        assignment: {
          ...this.state.assignment,
          ...obj,
          alternativeLanguage: obj.language
            ? { name: null, value: null }
            : obj.alternativeLanguage
            ? obj.alternativeLanguage
            : this.state.assignment.alternativeLanguage,
          typeOfAssignment: obj.typeOfAssignment
            ? obj.typeOfAssignment
            : assignment.typeOfAssignment,
          dateRange: {
            startTime: obj.dateRange
              ? obj.dateRange.startTime
              : assignment.dateRange.startTime,
            endTime: obj.dateRange
              ? obj.dateRange.endTime
              : assignment.dateRange.endTime,
            ranges: obj.dateRange
              ? obj.dateRange.ranges
              : assignment.dateRange.ranges,
            durationDifference: obj.dateRange
              ? obj.dateRange.durationDifference
              : assignment.dateRange.durationDifference,
          },
          selectedAssignmentTypeData: filteredItems,
        },
      });

      const callbackUpdateServices = allowSelectServices => {
        if (allowSelectServices && allowSelectServices.length) {
          let typeOfAssignment = allowSelectServices[0].ServiceIdentifier;

          allowSelectServices.forEach(allowSelect => {
            if (assignment.typeOfAssignment.value !== '') {
              if (
                allowSelect.ServiceIdentifier ===
                assignment.typeOfAssignment.value
              ) {
                // keep the same type of Assignment
                typeOfAssignment = assignment.typeOfAssignment;
              }
            } else {
              typeOfAssignment = assignment.typeOfAssignment;
            }
          });

          this.setState({
            assignment: {
              ...this.state.assignment,
              services: allowSelectServices,
              typeOfAssignment,
            },
            alternativeServices:
              this.checkAlternativeServices(allowSelectServices),
          });
        } else {
          this.setState({
            assignment: {
              ...this.state.assignment,
              services: [],
              typeOfAssignment: {},
            },
            alternativeServices: {},
          });
        }
      };
      const params = {
        language: obj.language
          ? obj.language.value == ''
            ? languages[1].value
            : obj.language.value
          : assignment.language?.value != '' && assignment.language.value
          ? assignment.language.value
          : obj.language?.value
          ? obj.language.value
          : languages[1].value,
        startTime: startDate
          .clone()
          .hours(startTime.hours())
          .minutes(startTime.minutes()),
        endTime: endDate
          .clone()
          .hours(endTime.hours())
          .minutes(endTime.minutes()),
      };

      executeGetServices(params, callbackUpdateServices);
    } else {
      let altServiceType = assignment.alternativeTypeOfAssignment;
      if (assignment.isEdit) {
        if (
          obj.typeOfAssignment.value === assignment.serviceIdentifierOrdered
        ) {
          altServiceType = assignment.alternativeTypeOfAssignmentDisplay;
        } else {
          altServiceType = { name: null, value: null };
        }
      } else {
        if (obj.typeOfAssignment) {
          if (filteredItems[0].DefaultDoAllowAlternativeService) {
            const selectedAltService = services.find(
              service =>
                service.ServiceIdentifier ===
                filteredItems[0].ServiceAlternatives.ServiceIdentifier,
            );
            altServiceType = {
              name: selectedAltService.ServiceName,
              value: selectedAltService.ServiceIdentifier,
            };
          }
        }
      }
      this.setState({
        assignment: {
          ...this.state.assignment,
          ...obj,
          alternativeLanguage: obj.language
            ? { name: null, value: null }
            : obj.alternativeLanguage
            ? obj.alternativeLanguage
            : this.state.assignment.alternativeLanguage,
          alternativeTypeOfAssignment: altServiceType,
          typeOfAssignment: obj.typeOfAssignment
            ? obj.typeOfAssignment
            : assignment.typeOfAssignment,
          ...(obj.typeOfAssignment ? this.resetMeetingRelatedData() : {}),

          selectedAssignmentTypeData: filteredItems,
          isAllowLowerLevel,
          isAllowOnlyLevel,
          genderRequirement,
          genderValue,
          certificationLevels,
          defaultDoAllowAlternativeService,
        },
      });
    }
  };

  handleContactPersonModal = (emailRequired, type = '', identifier) => {
    let { contactPerson } = this.state;
    if (identifier && identifier !== '') {
      let matchingContactPersonsList =
        this.state.assignment.contactPersons.filter(
          x => x.ContactPersonIdentifier == identifier,
        );
      matchingContactPersonsList =
        matchingContactPersonsList.length > 0
          ? matchingContactPersonsList[0]
          : {};

      contactPerson = {
        ...contactPerson,
        ...matchingContactPersonsList,
        editMode: true,
      };
    }
    this.setState({
      showContactPersonModal: true,
      contactPerson: {
        ...contactPerson,
        identifier,
        emailRequired,
        type,
      },
    });
  };

  setAssignmnetContactPersonBasedOnType = contactPerson => {
    if (contactPerson.type == 'ContactPersonUponInterpretation') {
      return {
        contactPerson: contactPerson.identifier,
        contactEmail: contactPerson.Email,
        contactDirectNumber: contactPerson.PhoneNumberDirect,
        contactNumber: contactPerson.PhoneNumberMobile,
      };
    }
    return {
      orderContactPerson: contactPerson.identifier,
      orderEmail: contactPerson.Email,
      orderContactDirectNumber: contactPerson.PhoneNumberDirect,
      orderContactNumber: contactPerson.PhoneNumberMobile,
    };
  };

  handleConfirm = () => {
    const { contactPerson } = this.state;
    const contactPersons = clone(this.state.assignment.contactPersons);
    let assignmentContact = {};
    if (contactPerson.identifier && contactPerson.identifier != '') {
      // contactPersons = contactPersons.map(x => {
      //   let editedModel = { ...x };
      //   if (x.ContactPersonIdentifier == contactPerson.identifier) {
      //     editedModel = {
      //       ...editedModel,
      //       ...contactPerson,
      //     };
      //   }
      //   return editedModel;
      // });
      assignmentContact =
        this.setAssignmnetContactPersonBasedOnType(contactPerson);
    } else {
      contactPerson.value = `newPerson-${generateUniqueId()}`;
      contactPerson.name = `${contactPerson.FirstName} ${
        contactPerson.LastName
      } ${contactPerson.Title ? `- ${contactPerson.Title}` : ''}`;
      contactPersons.push(contactPerson);
      this.props.updateContactPerson(contactPerson);
    }
    const assignmenttemp = {
      ...this.state.assignment,
      contactPersons,
      ...assignmentContact,
    };

    this.setState({
      assignment: assignmenttemp,
      contactPerson: createDefaultContactPerson(),
    });
  };

  handleChangeContactPerson = obj => {
    const { contactPerson } = this.state;
    this.setState({
      contactPerson: {
        ...contactPerson,
        ...obj,
      },
    });
  };

  handleContactPersonModalClose = () => {
    this.setState({
      showContactPersonModal: false,
      contactPerson: createDefaultContactPerson(),
    });
  };

  handleFormError = e => {
    if (e.length > 0) {
      e[0].input.scrollIntoView
        ? e[0].input.scrollIntoView({ behavior: 'smooth' })
        : e[0].input._element.scrollIntoView({ behavior: 'smooth' });
    } else {
      e.input.scrollIntoView
        ? e.input.scrollIntoView({ behavior: 'smooth' })
        : e.input._element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  isAdvancedAssigmentWizardNeeded = () => {
    const { assignment } = this.state;

    return (
      (assignment.genderValue && assignment.genderValue?.value != '') ||
      (assignment.certificationLevels &&
        assignment.certificationLevels?.value != '') ||
      (assignment.assignmentDescription &&
        assignment.assignmentDescription != '') ||
      (assignment.requestedInterpreter &&
        assignment.requestedInterpreter != '') ||
      (assignment.additionalRequirements &&
        assignment.additionalRequirements != '') ||
      (assignment.yourReferenceNumber && assignment.yourReferenceNumber != '')
    );
  };

  RouteLeavingModalHandler = value => {
    this.setState({
      visibleRouteLeavingModal: value,
    });
  };

  checkAlternativeServices = services => {
    const alternativeServices = {};
    services.forEach(service => {
      if (service.ServiceAlternatives) {
        const serviceAlternatives = service.ServiceAlternatives.map(
          subService => {
            const identifier = subService.ServiceIdentifier;
            const serviceFound = services.find(
              altService => altService.ServiceIdentifier === identifier,
            );
            return {
              value: identifier,
              name: serviceFound.ServiceName,
            };
          },
        );
        alternativeServices[service.ServiceIdentifier] = serviceAlternatives;
      }
    });
    return alternativeServices;
  };

  alternativeServiceCheck = () => {
    const { defaultDoAllowAlternativeService } = this.state.assignment;
    this.setState({
      assignment: {
        ...this.state.assignment,
        defaultDoAllowAlternativeService: !defaultDoAllowAlternativeService,
      },
    });
  };

  render() {
    const {
      assignment,
      showContactPersonModal,
      contactPerson,
      showPreview,
      showSuccessMessage,
      successMessageDetails,
      showAdvancedOrderWizard,
      visibleRouteLeavingModal,
    } = this.state;

    const {
      languages,
      onClose,
      profile,
      showQuickOrder,
      onSwitchToDetailOrder,
      skills,
      services,
      contactPersons,
      date,
      RouteLeavingGuardHandler,
    } = this.props;

    return (
      <React.Fragment>
        {(showPreview || showSuccessMessage) && !showAdvancedOrderWizard && (
          <Modal
            className="tv-workassignment__modal"
            onCloseClicked={() => {
              if (showSuccessMessage) {
                RouteLeavingGuardHandler(false);
                onClose();
              } else {
                this.setState({ showPreview: false });
              }
            }}
            isVisible={showPreview || showSuccessMessage}
            headerClassName={`tv-workassignment__modal-header ${
              isIE ? 'ie-fix-width' : ''
            }`}
            headerText={Translate({
              content: showSuccessMessage
                ? 'singleAssignment.successModalHeader'
                : 'singleAssignment.assignmentDetail',
            })}
            ieOptionClassName={isIE ? 'ie-fix-height' : ''}
          >
            {showPreview && (
              <PreviewAssignment
                onClose={() => {
                  if (this.isAdvancedAssigmentWizardNeeded()) {
                    this.setState({
                      showPreview: false,
                      showAdvancedOrderWizard: true,
                    });
                  } else {
                    this.setState({ showPreview: false });
                  }
                }}
                isBasic
                showPreview={showPreview}
              />
            )}
            {showSuccessMessage && (
              <SuccessMessage
                onClose={() => {
                  this.setState({
                    showPreview: false,
                    showSuccessMessage: false,
                  });
                  RouteLeavingGuardHandler(false);
                  onClose();
                }}
                mode={assignment.isEdit ? 'edit' : 'assignment'}
                onContinueBooking={services => {
                  this.setState({
                    showPreview: false,
                    showSuccessMessage: false,
                    assignment: {
                      ...createDefaultAssignment(
                        SINGLE_FORM,
                        skills,
                        this.state.assignment.services || [],
                        clone(contactPersons),
                        profile,
                        date,
                      ),
                    },
                  });
                }}
                visible={showSuccessMessage}
                assignmentDetails={successMessageDetails}
              />
            )}
          </Modal>
        )}

        {showAdvancedOrderWizard && (
          <AdvancedAssigmentWizard
            onSwitchToDetailOrder={onSwitchToDetailOrder}
            assignment={assignment}
            handleValidatorListener={this.handleValidatorListener}
            handleChange={this.handleChange}
            handleModal={this.handleContactPersonModal}
            languages={languages}
            profile={profile}
            onCloseAdvancedModal={() => {
              this.setState({ showAdvancedOrderWizard: false });
            }}
            onCreateNewDefaultForm={services => {
              this.setState({
                showPreview: false,
                showSuccessMessage: false,
                assignment: {
                  ...createDefaultAssignment(
                    SINGLE_FORM,
                    skills,
                    this.state.assignment.services || [],
                    clone(contactPersons),
                    profile,
                    date,
                  ),
                },
              });
            }}
            onCloseBooking={() => {
              onClose();
              RouteLeavingGuardHandler(false);
            }}
            visible={showAdvancedOrderWizard}
          />
        )}

        {showContactPersonModal && (
          <ContactPersonEdit
            {...contactPerson}
            showModal={showContactPersonModal}
            inOrderProcess
            onHandleModal={this.handleContactPersonModalClose}
          />
        )}

        <RouteLeavingModal
          isVisible={visibleRouteLeavingModal}
          onCloseClicked={() => {
            this.RouteLeavingModalHandler(false);
          }}
          onOverlayClick={() => {
            this.RouteLeavingModalHandler(false);
          }}
          onConfirmClick={() => {
            RouteLeavingGuardHandler(false);
            onClose();
          }}
        />

        <LeftDrawerSlide
          isVisible={showQuickOrder}
          hideClose
          hideExternalScroll
          onClose={onClose}
        >
          <Dialog
            className="tk-assignment-creation__container"
            isVisible
            onClose={() => {
              this.RouteLeavingModalHandler(true);
            }}
          >
            <div className="tk-assignment-list-box_header">
              {assignment.isEdit ? (
                `${Translate({ content: 'dashboard.editAssignment' })} ${
                  assignment?.OrderNumber && ` ${assignment?.OrderNumber}`
                }`
              ) : (
                <Translate content="dashboard.history" />
              )}
            </div>
            <div className="tk-assignment-list-box_body">
              <ValidatorForm
                ref={this.quickOrderRef}
                onSubmit={this.handleSubmit}
                onError={this.handleFormError}
              >
                <QuickOrderForm
                  {...assignment}
                  validatorListener={this.handleValidatorListener}
                  onChange={this.handleChange}
                  onHandleModal={this.handleContactPersonModal}
                  languages={languages}
                  alternativeLanguages={this.state.alternativeLanguages}
                  openAdvanceView={() => {
                    this.setState({ isAddDemands: true });
                    this.quickOrderRef.current.submit();
                  }}
                  onClose={() => {
                    this.RouteLeavingModalHandler(true);
                  }}
                  ContactPersonOrdererRequiresEmail={
                    profile.ContactPersonOrdererRequiresEmail
                  }
                  ContactPersonUponInterpretationRequiresEmail={
                    profile.ContactPersonUponInterpretationRequiresEmail
                  }
                  createdFrom="singleAssignment"
                  onSwitchToMultipleOrder={this.handleSwitchToMultipleOrder}
                  isSingle
                  isAltLanguageAdded={this.state.isAltLanguageAdded}
                  addAltLanguage={this.addAltLanguage}
                  alternativeServiceTypes={this.state.alternativeServiceTypes}
                  isAltServicesAdded={this.state.isAltServicesAdded}
                  addAltServiceType={this.addAltServiceType}
                  alternativeServiceCheck={this.alternativeServiceCheck}
                />
              </ValidatorForm>
            </div>
          </Dialog>
        </LeftDrawerSlide>
      </React.Fragment>
    );
  }
}

QuickOrder.propTypes = propTypes;
QuickOrder.defaultProps = defaultProps;

const mapStateToProps = state => {
  const assignment = state.workAssignment.assignmentQuickOrder;
  const { assignmentTemp } = state.workAssignment;
  const contactPersons = state.user.contactPersons || [];
  const newContactPersons = state.workAssignment.contactPersons || [];
  const profile = state.user.profile || [];
  const skills = state.skill.skills || [];
  const substituteSkills = state.skill.substituteSkills || [];
  const languages = skills.map((item, i) => ({
    value: item.SkillIdentifier,
    name: item.SkillName,
  }));
  const alternativeLanguages = {};
  const skillIdentifiers = Object.keys(substituteSkills);

  skillIdentifiers.forEach(skill => {
    const availableSubSkills = substituteSkills[skill].map(subSkill => ({
      value: subSkill.SkillIdentifier,
      name: subSkill.SkillName,
    }));
    alternativeLanguages[skill] = availableSubSkills;
  });

  return {
    assignment,
    assignmentTemp,
    skills,
    contactPersons,
    newContactPersons,
    profile,
    languages,
    alternativeLanguages,
  };
};

const mapDispatchToProps = dispatch => ({
  executeGetContactPersons: () => {
    dispatch(fetchContactPersons());
  },
  executeGetServices: (params, callback) => {
    dispatch(fetchServices(params, callback));
  },
  onSwitchToDetailOrder: assignment => {
    dispatch(onUpdateNewAssignmentsTemp(assignment));
  },
  updateContactPerson: contactPerson => {
    dispatch(UpdateContactPersons(contactPerson));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickOrder);
